import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'
import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import { API_URL } from '../../../../config'
import useChangeHistoryRestore from '../../../../hooks/api/mutations/useChangeHistoryRestore'
import useChangeHistory from '../../../../hooks/api/queries/useChangeHistory'
import useChangeHistoryInfo from '../../../../hooks/api/queries/useChangeHistoryInfo'
import { useSearchParams } from 'react-router-dom'
import Modal from 'react-modal'
import { modalCustomStyles } from '../../../../styles/modalStyles'
import useChangeHistoryChanges from '../../../../hooks/api/queries/useChangeHistoryChanges'
import { Change, diffJson } from 'diff'
import type { App } from '../../../../hooks/api/queries/useApp'
import Autocomplete from '../../../../components/Autocomplete'
import axios from 'axios'

interface ChangeHistoryProps {
  app: App
}

const ChangeHistory: React.FC<ChangeHistoryProps> = ({ app }) => {
  //===============
  // QUERYSTRING
  //==============
  const [searchParams] = useSearchParams()

  type User = {
    id: string
    firstName: string
    lastName: string
  }

  type Page = {
    id: string
    name: string
    slug: string
    language: string
  }

  //===========
  // S T A T E
  //===========

  const [user, setUser] = useState<User>({
    id: '',
    firstName: '',
    lastName: '',
  })
  const [page, setPage] = useState<Page>({
    id: '',
    language: '',
    name: '',
    slug: '',
  })

  const [pagination, setPagination] = useState({ page: 1, pageSize: 25 })

  const [showChangesModal, setShowChangesModal] = useState<boolean>(false)
  const [changeId, setChangeId] = useState<number>(0)
  const [historyChanges, setHistoryChanges] = useState<Change[]>([])

  const { data: changeHistory } = useChangeHistory({
    appId: app.appId,
    appEnv: app.appEnv,
    pageId: page?.id,
    language: page?.language,
    userId: user?.id,
    userName:
      user?.firstName && user?.lastName
        ? `${user.firstName} ${user.lastName}`
        : '',
    pagination,
  })
  const { data: changeHistoryInfo } = useChangeHistoryInfo(
    app.appId,
    app.appEnv
  )
  const { data: changeHistoryChanges } = useChangeHistoryChanges(
    app.appId,
    app.appEnv,
    changeId
  )

  const restore = useChangeHistoryRestore(app.appId, app.appEnv)

  const token = localStorage.getItem('token')

  //=================
  // H A N D L E R S
  //=================

  const handleRestore = (changeId: number) => restore.mutate(changeId)

  const handlePageChange = (e: { selected: number }) => {
    setPagination({ ...pagination, page: e.selected + 1 })
  }
  const handlePageSize = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setPagination({ page: 1, pageSize: parseInt(e.target.value) })

  //=================
  // E F F E C T S
  //=================
  useEffect(() => {
    if (searchParams.get('page')) {
      // old "page" (pageName) parameter
      // Get page for default language
      const getPageByName = async () => {
        const page = await axios.get<Page>(
          `/account/apps/${app.appId}/environments/${
            app.appEnv
          }/get_translation?name=${searchParams.get('page')}`
        )
        // If page exists => Set Page
        if (page.data) {
          setPage(page.data)
        }
      }
      getPageByName()
    }
    if (searchParams.get('p') && searchParams.get('l')) {
      // Get Page new way (pageId & language)
      const getPageByIdLanguage = async () => {
        const page = await axios.get<Page>(
          `/account/apps/${app.appId}/environments/${
            app.appEnv
          }/get_translation?pageId=${searchParams.get(
            'p'
          )}&language=${searchParams.get('l')}`
        )
        // If page exists => Set Page
        if (page.data) {
          setPage(page.data)
        }
      }
      getPageByIdLanguage()
    }
  }, [searchParams.get('page'), searchParams.get('p'), searchParams.get('l')])

  useEffect(() => {
    if (changeHistoryChanges) {
      const dataBefore = JSON.parse(changeHistoryChanges.dataBefore)
      const dataAfter = JSON.parse(changeHistoryChanges.dataAfter)

      setHistoryChanges(
        diffJson(dataBefore || '', dataAfter || '', {
          newlineIsToken: true,
        })
      )
    }
  }, [changeHistoryChanges])

  return (
    <Card title="Change history">
      <div>
        <p className="mb-6">
          Here you will see the events history for each page, for the last{' '}
          {changeHistoryInfo?.retentionDays}{' '}
          {changeHistoryInfo?.retentionDays === 1 ? 'day' : 'days'}.
          <br />
          You'll be able to undo any action and, in this way, go back to a
          previous point in time or restore a page deleted by error.
        </p>

        {(page?.id ||
          user?.id ||
          (!!changeHistory?.data.length && changeHistory?.data.length > 0)) && (
          <div className="flex space-x-4 items-center">
            <div className="flex space-x-4">
              <Autocomplete
                label="Users"
                placeholder="Type to search..."
                value={user}
                onChange={(user) => setUser(user)}
                getOptions={async (input) => {
                  if (!input) {
                    return []
                  }
                  const users = await axios.get<User[]>(
                    `/account/apps/${app.appId}/environments/${app.appEnv}/users?q=${input}&pageSize=8`
                  )
                  return users.data.map((user) => ({
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                  }))
                }}
                getKey={(user) => user?.id}
                getLabel={(user) => {
                  if (user.id) {
                    return `${user.firstName} ${user.lastName}`
                  }
                  return ''
                }}
                getNoOptionsMessage={(input) => `No users for ${input}`}
              />
            </div>{' '}
            <div className="flex space-x-4 items-center">
              <Autocomplete
                label="Pages"
                placeholder="Type to search..."
                value={page}
                onChange={(page) => setPage(page)}
                getOptions={async (input) => {
                  if (!input) {
                    return []
                  }
                  const pages = await axios.get<Page[]>(
                    `/account/apps/${app.appId}/environments/${app.appEnv}/translations?q=${input}&pageSize=8`
                  )
                  return pages.data.map((page) => ({
                    id: page.id,
                    language: page.language,
                    name: page.name,
                    slug: page.slug,
                  }))
                }}
                getKey={(page) => page?.id}
                getLabel={(page) => {
                  if (page.id) {
                    return `${page?.name || ''} (${page?.language || ''})`
                  }
                  return ''
                }}
                getNoOptionsMessage={(input) => `No pages for ${input}`}
              />
            </div>
          </div>
        )}

        {changeHistory?.data.length && changeHistory?.data.length > 0 ? (
          <>
            <table className="border-collapse border border-gray-300 text-xs w-full">
              <thead>
                <tr>
                  <th className="border border-gray-300 py-1 px-2 text-center">
                    Date time
                  </th>
                  <th className="border border-gray-300 py-1 px-2 text-center">
                    Page
                  </th>
                  <th className="border border-gray-300 py-1 px-2 text-center">
                    User
                  </th>
                  <th className="border border-gray-300 py-1 px-2 text-center">
                    Action
                  </th>
                  <th className="border border-gray-300 py-1 px-2 text-center">
                    Changes
                  </th>
                  <th className="border border-gray-300 py-1 px-2 text-center">
                    Restore
                  </th>
                </tr>
              </thead>
              <tbody>
                {changeHistory.data.map((change) => {
                  return (
                    <tr key={change.id}>
                      <td className="border border-gray-300 py-1 px-2 text-center">
                        {format(parseISO(change.createdAt), 'yyyy-MM-dd HH:mm')}
                      </td>
                      <td className="border border-gray-300 py-1 px-2 space-x-2">
                        <span className="bg-gray-200 rounded border border-gray-400 px-1 uppercase font-medium">
                          {change.language}
                        </span>{' '}
                        <b>{change.name}</b>
                      </td>
                      <td className="border border-gray-300 py-1 px-2 text-center">
                        <b>{change.user}</b>
                      </td>
                      <td className="border border-gray-300 py-1 px-2 text-center">
                        <div
                          className={classNames(
                            'py-px px-1 rounded font-semibold text-xs uppercase',
                            {
                              'bg-blue-100 text-blue-600':
                                change.action.indexOf('UPDATE') > -1,
                            },
                            {
                              'bg-red-100 text-red-600':
                                change.action.indexOf('DELETE') > -1,
                            },
                            {
                              'bg-lime-100 text-lime-600':
                                change.action.indexOf('CREATE') > -1,
                            },
                            {
                              'bg-violet-100 text-violet-600':
                                change.action.indexOf('WORKING_COPY') > -1,
                            },
                            {
                              'border-2 border-pink-700':
                                change.action.startsWith('UNDO_'),
                            }
                          )}
                        >
                          {change.action.replace(/_/g, ' ')}
                        </div>
                      </td>
                      <td className="border border-gray-300 py-1 px-2 text-center">
                        <button
                          type="button"
                          className="block w-full text-center text-accent-600 hover:text-accent-700"
                          onClick={() => {
                            setChangeId(change.id)
                            setShowChangesModal(true)
                          }}
                        >
                          View changes
                        </button>
                      </td>
                      <td className="border border-gray-300 py-1 px-2 text-center">
                        <Button
                          type="button"
                          color="cyan"
                          loading={false}
                          onClick={() => handleRestore(change.id)}
                        >
                          Restore
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="flex justify-between w-full text-gray-500 mt-4">
              <ReactPaginate
                containerClassName="flex  space-x-3 items-center"
                previousLabel={
                  <FaChevronLeft className="text-gray-300 hover:text-gray-400" />
                }
                nextLabel={
                  <FaChevronRight className="text-gray-300 hover:text-gray-400" />
                }
                breakLabel={'...'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={pagination.page - 1}
                //@ts-ignore
                pageCount={Math.ceil(
                  parseInt(
                    changeHistory?.headers['x-total-count'] || '29',
                    10
                  ) / pagination.pageSize
                )}
                activeClassName="text-cyan-600 font-bold text-xl"
                onPageChange={handlePageChange}
              />
              <div className="flex space-x-4 items-center">
                <label htmlFor="page-size" />
                <select
                  id="page-size"
                  onChange={handlePageSize}
                  value={pagination.pageSize}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </>
        ) : (
          <div className="mt-4 border rounded p-3 bg-gray-50">
            No changes to show for the plan's retention period (last{' '}
            {changeHistoryInfo?.retentionDays}{' '}
            {changeHistoryInfo?.retentionDays === 1 ? 'day' : 'days'}).
          </div>
        )}
      </div>

      <Modal
        isOpen={!!showChangesModal}
        onRequestClose={() => setShowChangesModal(false)}
        style={modalCustomStyles}
      >
        <div className="p-4 max-w-4xl">
          <div className="flex items-center justify-end space-x-2 mb-2 text-sm">
            {changeHistoryChanges?.dataBefore && (
              <a
                className="text-accent-600 hover:text-accent-700"
                href={`${API_URL}/account/apps/${app.appId}/environments/${app.appEnv}/change_history/${changeId}/data_before?t=${token}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Before
              </a>
            )}
            {changeHistoryChanges?.dataBefore &&
              changeHistoryChanges?.dataAfter && (
                <div className="text-gray-400">|</div>
              )}
            {changeHistoryChanges?.dataAfter && (
              <a
                className="text-accent-600 hover:text-accent-700"
                href={`${API_URL}/account/apps/${app.appId}/environments/${app.appEnv}/change_history/${changeId}/data_after?t=${token}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                After
              </a>
            )}
          </div>

          <div className="p-4 border bg-stone-100 rounded h-96 overflow-y-auto">
            <pre className="text-xs">
              {historyChanges.map((change, index) =>
                change.added ? (
                  <div
                    key={index}
                    className="pl-2 text-green-600 border-l-2 border-green-500"
                  >
                    {change.value}
                  </div>
                ) : change.removed ? (
                  <div
                    key={index}
                    className="pl-2 text-red-500 border-l-2 border-red-500"
                  >
                    {change.value}
                  </div>
                ) : (
                  <div
                    key={index}
                    className="pl-2 border-l-2 border-transparent text-stone-500"
                  >
                    {change.value}
                  </div>
                )
              )}
            </pre>
          </div>
        </div>
      </Modal>
    </Card>
  )
}

export default ChangeHistory
