import classNames from 'classnames'
import { useState } from 'react'
import { FaCircle } from 'react-icons/fa'
import { FiCopy } from 'react-icons/fi'

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    } catch (error) {
      setIsCopied(false)
      //console.error('Unable to copy to clipboard:', error)
    }
  }

  return { isCopied, copyToClipboard }
}

type TabCode = {
  tabKey: string
  tabLabel: string
  code: string
}

interface SimpleCodeTabsProps {
  tabs: Array<TabCode>
}

export default function SimmpleCodeTabs({ tabs }: SimpleCodeTabsProps) {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].tabKey)
  const { copyToClipboard, isCopied } = useCopyToClipboard()

  if (!tabs || !tabs.length) {
    return null
  }

  return (
    <div className="my-3 overflow-x-auto">
      <ul role="tablist" className="border-b-2 border-gray-300 flex">
        {tabs.map((tab) => (
          <li key={tab.tabKey} role="presentation" className="-mb-0.5">
            <a
              role="tab"
              aria-selected={selectedTab === tab.tabKey}
              onClick={(e) => {
                e.preventDefault()
                setSelectedTab(tab.tabKey)
              }}
              href={`#tab-panel-${tab.tabKey}`}
              id={`tab-${tab.tabKey}`}
              className={classNames(
                'flex px-5 border-b-2',
                selectedTab === tab.tabKey
                  ? 'border-blue-600 text-gray-950 font-semibold'
                  : 'border-gray-300 text-gray-500 font-normal'
              )}
            >
              {tab.tabLabel}
            </a>
          </li>
        ))}
      </ul>
      {tabs.map((tab) => (
        <section
          id={`tab-panel-${tab.tabKey}`}
          aria-labelledby={tab.tabLabel}
          role="tabpanel"
          className="mt-4 group"
          hidden={selectedTab !== tab.tabKey}
        >
          <div>
            <figure>
              <figcaption className="bg-gray-100 py-3 px-4 border border-b-0 flex items-center gap-x-[3px] text-[10px]">
                <FaCircle className="text-gray-300" />
                <FaCircle className="text-gray-300" />
                <FaCircle className="text-gray-300" />
              </figcaption>
              <pre className="bg-gray-50 border relative">
                <code className="block text-sm py-4 px-4 text-blue-800">
                  {tab.code}
                </code>
                <button
                  onClick={() => copyToClipboard(tab.code)}
                  className={classNames(
                    'absolute top-[10px] right-[10px] opacity-0 group-hover:opacity-100 transition-opacity p-2 border border-gray-300 hover:bg-gray-100 rounded text-gray-600',
                    { 'opacity-100': isCopied }
                  )}
                >
                  <FiCopy />
                  <div
                    className={classNames(
                      'absolute top-[4px] right-[40px] text-xs py-1 px-2 bg-green-600 rounded text-white font-sans font-bold transition-opacity',
                      isCopied ? 'opacity-100' : 'opacity-0'
                    )}
                  >
                    Copied
                  </div>
                </button>
              </pre>
            </figure>
          </div>
        </section>
      ))}
    </div>
  )
}
