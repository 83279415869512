import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import type { User } from '../queries/useProfile'

interface SignUpArgs {
  firstName: string
  lastName: string
  company?: string
  email: string
  password: string
  acceptTerms: boolean
  invitationToken?: string
}

const signUp = async (signupData: SignUpArgs) => {
  const { data } = await axios.post<{ user: User; authToken: string }>(`/account/profile`, signupData)
  return data
}

const useSignup = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (signupData: SignUpArgs) => {
      return signUp(signupData)
    },
    {
      onSuccess: (data: { user: User }) => {
        // update cache
        const {
          user: { id, accountId, accountName, avatarUrl, firstName, lastName, email, company, role, isVerified },
        } = data

        queryClient.setQueryData(['profile'], {
          id,
          accountId,
          accountName,
          avatarUrl,
          firstName,
          lastName,
          email,
          company,
          role,
          isVerified,
        })
      },
      onError: () => {},
    }
  )
}

export default useSignup
