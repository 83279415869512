import React from 'react'

import Button from '../../components/Button'
import useSyncAppEnvironment from '../../hooks/api/mutations/useSyncAppEnvironment'
import { Environment } from '../../hooks/api/queries/useApp'

interface SyncEnvironmentProps {
  appId: string
  environment: Environment
  onCloseSyncModal: any
}

const SyncEnvironment: React.FC<SyncEnvironmentProps> = ({
  appId,
  environment,
  onCloseSyncModal,
}) => {
  const syncEnvironment = useSyncAppEnvironment(appId)

  const handleSyncEnvironment = () => {
    syncEnvironment.mutate(environment.name)
    onCloseSyncModal()
  }

  return (
    <div className="px-12 py-8 max-w-md">
      <div className="flex flex-col items-center mb-4">
        <h2 className="text-lg font-semibold text-center mb-5">
          Sync "{environment.name}" with Main
        </h2>
        <p className="mb-3 text-sm text-gray-600">
          The content of this environment (assets included) will be completely
          replaced by the content of the Main environment. This environment's
          change history will be reset.
        </p>
        <p className="mb-5 text-sm text-gray-600">
          Other configurations, like users or build hooks, will not be affected.
        </p>
      </div>
      <div className="flex justify-between space-x-4">
        <Button color="gray" className="flex-1" onClick={onCloseSyncModal}>
          Cancel
        </Button>
        <Button
          color="cyan"
          className="flex-1"
          loading={syncEnvironment.isLoading}
          onClick={handleSyncEnvironment}
        >
          Sync
        </Button>
      </div>
    </div>
  )
}

export default SyncEnvironment
