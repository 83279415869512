import React from 'react'
import {
  // FiEdit2,
  //  FiXCircle,
  //  FiUser,
  FiDownload,
  FiUpload,
  FiTrash,
} from 'react-icons/fi'
import type { App, Environment } from '../../hooks/api/queries/useApp'
import Button from '../../components/Button'
import classNames from 'classnames'

interface EnvironmentsRowProps {
  environment: Environment
  onOpenSyncModal: any
  onOpenPushModal: any
  onOpenDeleteModal: any
  app: App
}

const EnvironmentsRow: React.FC<EnvironmentsRowProps> = ({
  environment,
  app,
  onOpenSyncModal,
  onOpenPushModal,
  onOpenDeleteModal,
}) => {
  return (
    <>
      {/* BRANCH LINES */}
      {!environment.isMain && (
        <div className="flex -z-10">
          <div className="w-1/4"></div>
          <div className="w-1/4 h-40 -mt-12 border-l-2 border-b-2"></div>
        </div>
      )}

      {/* ENVIRONMENT CARD */}
      <div
        className={classNames(
          'flex flex-col justify-between w-1/2 p-4 rounded border-2',
          {
            'relative h-32 -mt-20 -left-20 self-end ': !environment.isMain,
          },
          environment.name === app.appEnv
            ? 'bg-sky-50 border-sky-100'
            : 'bg-gray-100 border-gray-200'
        )}
      >
        <div className="flex w-full items-start space-between">
          {/* ENV NAME & LABEL */}
          <div className="flex-1">
            {environment.isMain ? (
              <div
                className={`mt-1 inline-block text-xs font-bold py-0.5 px-2 ${
                  app.appEnv === environment.name
                    ? 'bg-sky-200 text-sky-800'
                    : 'bg-gray-300 text-gray-700'
                } rounded uppercase tracking-wide`}
              >
                Main
              </div>
            ) : (
              <div
                className={`mt-1 inline-block text-xs font-semibold py-0.5 px-2 ${
                  app.appEnv === environment.name
                    ? 'bg-sky-200 text-sky-800 '
                    : 'bg-gray-300 text-gray-700'
                } rounded uppercase tracking-wide`}
              >
                Sandbox
              </div>
            )}
            <div className="mt-1 ml-1 text-lg">{environment.name}</div>
          </div>
        </div>
        {/* SYNC & PUSH BTNs */}
        {!environment.isMain && (
          <div className="flex items-center space-x-3 justify-end self-end">
            <Button
              color="cyan"
              disabled={app.appEnv !== 'main'}
              className="flex items-center space-x-2 pl-2 pr-2 pt-1 pb-1 text-xs"
              onClick={onOpenSyncModal(environment)}
            >
              <FiDownload />
              <span>Sync</span>
            </Button>
            <Button
              color="cyan"
              disabled={app.appEnv !== 'main'}
              className="flex items-center space-x-2 pl-2 pr-2 pt-1 pb-1 text-xs"
              onClick={onOpenPushModal(environment)}
            >
              <FiUpload />
              <span>Push</span>
            </Button>
            <Button
              color="gray"
              disabled={app.appEnv !== 'main'}
              className="flex items-center space-x-2 pl-2 pr-2 pt-1 pb-1 text-xs"
              onClick={onOpenDeleteModal(environment)}
            >
              <FiTrash />
              <span>Delete</span>
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default EnvironmentsRow
