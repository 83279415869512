import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import * as gtag from '../../utils/gtag'

import Button from '../../components/Button'
import FormField from '../../components/FormField'
import imgLogo from '../../images/reactbricks-logo-v.svg'
import useSsoLogin from '../../hooks/api/mutations/useSsoLogin'

type SsoLoginData = {
  uniqueAppIdentifier: string
}

const SsoLogin: React.FC = () => {
  // FORM
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SsoLoginData>()

  const [loading, setLoading] = useState(false)

  const login = useSsoLogin()

  const handleSsoLogin = handleSubmit(({ uniqueAppIdentifier }) => {
    setLoading(true)
    login
      .mutateAsync({ uniqueAppIdentifier })
      .then((res) => {
        if (res && res.result === 'OK' && res.redirectUrl) {
          // Set LocalStorage
          window.location.href = res.redirectUrl

          // Analytics Event
          gtag.trackEvent({
            category: 'login',
            action: 'login',
            label: 'SsoLogin completed',
          })

          // Redirect
          // navigate('/apps')
          // if (res.user.role === 'NEW_USER') {
          //   navigate('/verify-name')
          // } else {
          //   navigate('/apps')
          // }
        } else {
          // Error...
        }
      })
      .finally(() => setLoading(false))
  })

  return (
    <div>
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1464618663641-bbdd760ae84a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80), linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.2)); background-blend-mode: overlay; background-size: cover; background-position: 50% 20% }`}</style>
      </Helmet>
      <header className="py-12 flex justify-center">
        <a
          href="https://reactbricks.com"
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <img src={imgLogo} alt="logo" className="w-32" />
        </a>
      </header>

      <div className="shadow-lg py-8 px-10 max-w-sm rounded mx-auto bg-white">
        <h1 className="text-center text-gray-600 mb-6">
          Welcome to React Bricks
        </h1>

        <form onSubmit={handleSsoLogin}>
          <div className="mb-4">
            <FormField
              fieldName="uniqueAppIdentifier"
              label="SSO App Identifier"
              labelClassName="block text-sm leading-5 font-medium text-gray-700"
              type="text"
              register={register}
              validation={{
                required: 'Enter your SSO App Identifier',
              }}
              error={errors.uniqueAppIdentifier}
            />
          </div>
          <Button full color="cyan" type="submit" loading={loading}>
            Continue
          </Button>
        </form>

        <div className="mt-6 flex justify-center text-sm gap-x-1">
          <Link to="/" className="text-blue-600 hover:text-blue-700">
            Login with email and password
          </Link>
        </div>
      </div>
      <div className="mt-4 mb-16 text-gray-700 text-center text-sm">
        © {new Date().getFullYear()}{' '}
        <a
          href="https://reactbricks.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-700"
        >
          ReactBricks.com
        </a>
      </div>
    </div>
  )
}

export default SsoLogin
