import { createRoot } from 'react-dom/client'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './styles/tailwind.css'
import './styles/styles.css'

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// )

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
