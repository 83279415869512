import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { Plan } from '../../../components/PlanPill'
import { ProviderName } from '../../../pages/LoggedApp/Apps/App/SingleSignOn'

export type Language = {
  code: string
  name: string
}

export type Environment = {
  name: string
  isMain: boolean
}

export type BackupLogRow = {
  id: number
  status: 'IN_PROGRESS' | 'COMPLETED' | 'FAILED'
  pages: number
  images: number
  files: number
  jsonSize: number
  assetsSize: number
  assetsNumber: number
  error: string
  createdAt: string
  finishedAt: string
}

export interface App {
  appId: string
  apiKey: string
  appEnv: string
  appName: string
  isOwner: boolean
  role: 'ADMIN' | 'EDITOR'
  backup: {
    status: '' | 'PENDING' | 'READY'
  }
  defaultLanguage: string
  deployHookUrl: string
  deployHookMethod: string
  deployHookTriggerOnScheduledPublishing: string
  deployHookHeaderKey1: string
  deployHookHeaderValue1: string
  deployHookHeaderKey2: string
  deployHookHeaderValue2: string
  deployHookPayload: string
  deployHookStagingUrl: string
  deployHookStagingMethod: string
  deployHookStagingTriggerOnScheduledPublishing: string
  deployHookStagingHeaderKey1: string
  deployHookStagingHeaderValue1: string
  deployHookStagingHeaderKey2: string
  deployHookStagingHeaderValue2: string
  deployHookStagingPayload: string
  deployHookDevUrl: string
  deployHookDevMethod: string
  deployHookDevTriggerOnScheduledPublishing: string
  deployHookDevHeaderKey1: string
  deployHookDevHeaderValue1: string
  deployHookDevHeaderKey2: string
  deployHookDevHeaderValue2: string
  deployHookDevPayload: string
  firstName?: string
  languages: Language[]
  plan: Plan
  subscription: {
    maxLocales: number
    maxPages: number
    maxStories: number
    changeHistoryRetentionDays: number
    support: string
    planId: number
    cancelUrl: string
    updateUrl: string
    periodTo: string
    planActivatedAt: string
    maxUsers: number
    numUsers: number
    description: string
    deployHookStaging: boolean
    deployHookDev: boolean
    eventsLog: boolean
    flexibleRoles: boolean
    environments: boolean
    maxEnvironments: number
    numEnvironments: number
    workingCopy: boolean
    approvalWorkflow: boolean
    objectStorageBackup: boolean
    objectStorageBackupDaily: boolean
    sso: boolean
    features: any[]
  }
  eventsHookUrl: string
  eventsHookAuthToken: string
  hostname?: string
  liveDemo?: { expiresAt: string; deleteAppAt: string; status: string }
  environments: Environment[]
  useWorkingCopy: boolean
  useApprovalWorkflow: boolean
  useObjectStorageBackup: boolean
  useSso: boolean
  adminUrl: string
  sso?: {
    provider: ProviderName
    certificate: string
    redirectUrl: string
    uniqueAppIdentifier: string
    forceSso: boolean
    metadataUrl: string
    entityId: string
    acsUrl: string
  }
  objectStorageBackup?: {
    frequency: 'DAILY' | 'WEEKLY'
    weekDay?:
      | 'MONDAY'
      | 'TUESDAY'
      | 'WEDNESDAY'
      | 'THURSDAY'
      | 'FRIDAY'
      | 'SATURDAY'
      | 'SUNDAY'
    endpoint: string
    region: string
    accessKeyId: string
    secretAccessKey: string
    bucket: string
    lastBackupLog?: BackupLogRow
    lastCompletedBackupLog?: BackupLogRow
  }
}

const useApp = (appId?: string, appEnv?: string) => {
  const queryClient = useQueryClient()
  return useQuery(
    ['app', appId, appEnv],
    async () => {
      const { data } = await axios.get<App>(
        `/account/apps/${appId}/environments/${appEnv}`
      )
      return data
    },
    {
      enabled: !!appId && !!appEnv,
      initialData: () => {
        const apps = (queryClient.getQueryData(['apps']) || []) as App[]
        return apps.find((app) => app.appId === appId && app.appEnv === appEnv)
      },
      refetchOnWindowFocus: false,
      //staleTime: 30 * 60 * 1000, // 30 minuti
      //refetchInterval: 15000,
    }
  )
}

export default useApp
