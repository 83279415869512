import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useEjectLiveDemo = (appId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async () => await axios.post(`/account/live_demo/${appId}/eject`, {}).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['app', appId])
        cogoToast.success('Your app will be kept in your dashboard', { position: 'bottom-right' })
      },
      onError: (error: any) => {
        console.log(error, 'error')
      },
    }
  )
}

export default useEjectLiveDemo
