import React from 'react'
import { useForm } from 'react-hook-form'
import { FiActivity } from 'react-icons/fi'

import type { App } from '../../hooks/api/queries/useApp'
import usePatchApp from '../../hooks/api/mutations/usePatchApp'
import useTestConnection from '../../hooks/api/mutations/useTestObjectStorageConnection'
import Card from '../Card'
import FormField from '../FormField'
import Button from '../Button'

import imgScheduledBackup from '../../images/form_scheduled_backup.png'
import BackupLogTable from './BackupLogTable'

interface ScheduledBackupProps {
  app: App
}

type ObjectStorageBackupForm = {
  useObjectStorageBackup: boolean
  frequency: 'DAILY' | 'WEEKLY'
  weekDay?:
    | 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY'
    | 'SUNDAY'
  endpoint: string
  region: string
  accessKeyId: string
  secretAccessKey: string
  bucket: string
}

const ScheduledBackup: React.FC<ScheduledBackupProps> = ({ app }) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<ObjectStorageBackupForm>({
    defaultValues: {
      useObjectStorageBackup: app.useObjectStorageBackup,
      frequency: !app.subscription.objectStorageBackupDaily
        ? 'WEEKLY'
        : app.objectStorageBackup?.frequency || 'DAILY',
      weekDay: app.objectStorageBackup?.weekDay || 'MONDAY',
      endpoint: app.objectStorageBackup?.endpoint,
      region: app.objectStorageBackup?.region,
      accessKeyId: app.objectStorageBackup?.accessKeyId,
      secretAccessKey: app.objectStorageBackup?.secretAccessKey,
      bucket: app.objectStorageBackup?.bucket,
    },
  })

  const mutation = usePatchApp(app?.appId, app?.appEnv)
  const testConnection = useTestConnection(app?.appId, app?.appEnv)

  const handleTestConnection = () => {
    testConnection.mutateAsync({
      endpoint: watch('endpoint'),
      region: watch('region'),
      accessKeyId: watch('accessKeyId'),
      secretAccessKey: watch('secretAccessKey'),
      bucket: watch('bucket'),
    })
  }

  const backupFrequencyOptions = app.subscription.objectStorageBackupDaily
    ? [
        { value: 'DAILY', label: 'Daily' },
        { value: 'WEEKLY', label: 'Weekly' },
      ]
    : [{ value: 'WEEKLY', label: 'Weekly' }]

  return (
    <Card
      title="Scheduled Backup on S3-compatible storage"
      helpText="Schedule a backup of all content and assets on your own object storage."
      available={!!app.subscription.objectStorageBackup}
    >
      <form
        onSubmit={handleSubmit((data) => {
          const { useObjectStorageBackup, ...rest } = data
          mutation.mutateAsync({
            useObjectStorageBackup,
            objectStorageBackup: rest,
          })
        })}
      >
        <div className="mb-4">
          <FormField
            fieldName="useObjectStorageBackup"
            type="checkbox"
            label="Enable Scheduled Backup"
            register={register}
            disabled={!app.subscription.objectStorageBackup}
          />
        </div>
        {watch('useObjectStorageBackup') && (
          <>
            <div className="mb-4">
              <FormField
                fieldName="frequency"
                type="select"
                label="Frequency"
                options={backupFrequencyOptions}
                register={register}
                validation={{ required: 'Required' }}
                error={errors.frequency}
              />
            </div>
            {watch('frequency') === 'WEEKLY' && (
              <div className="mb-4">
                <FormField
                  fieldName="weekDay"
                  type="select"
                  label="Week Day"
                  options={[
                    { value: 'MONDAY', label: 'Monday' },
                    { value: 'TUESDAY', label: 'Tuesday' },
                    { value: 'WEDNESDAY', label: 'Wednesday' },
                    { value: 'THURSDAY', label: 'Thursday' },
                    { value: 'FRIDAY', label: 'Friday' },
                    { value: 'SATURDAY', label: 'Saturday' },
                    { value: 'SUNDAY', label: 'Sunday' },
                  ]}
                  register={register}
                  validation={{ required: 'Required' }}
                  error={errors.weekDay}
                />
              </div>
            )}
            <div className="p-6 border rounded-lg shadow-lg mb-6">
              <div className="mb-4">
                <FormField
                  fieldName="endpoint"
                  type="text"
                  label="Endpoint"
                  register={register}
                  validation={{ required: 'Required' }}
                  error={errors.endpoint}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="region"
                  type="text"
                  label="Region"
                  register={register}
                  validation={{ required: 'Required' }}
                  error={errors.region}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="accessKeyId"
                  type="text"
                  label="Access Key Id"
                  register={register}
                  validation={{ required: 'Required' }}
                  error={errors.accessKeyId}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="secretAccessKey"
                  type="text"
                  label="Secret Access Key"
                  register={register}
                  validation={{ required: 'Required' }}
                  error={errors.secretAccessKey}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="bucket"
                  type="text"
                  label="Bucket"
                  register={register}
                  validation={{ required: 'Required' }}
                  error={errors.bucket}
                />
              </div>
              <Button
                color="accent"
                className="flex items-center space-x-2 pl-2 pr-2 pt-1 pb-1 text-sm"
                onClick={handleTestConnection}
              >
                <FiActivity />
                <span>Test connection</span>
              </Button>
            </div>
            <Button type="submit" color="cyan" loading={mutation.isLoading}>
              Update backup scheduling
            </Button>

            <div className="mt-12 border p-6 bg-gray-50">
              <h3 className="font-bold mb-3">Latest backup</h3>
              <BackupLogTable
                backupLogRow={app.objectStorageBackup?.lastBackupLog}
              />
              {app.objectStorageBackup?.lastCompletedBackupLog?.id !==
                app.objectStorageBackup?.lastBackupLog?.id && (
                <>
                  <h3 className="mt-8 font-bold mb-3">
                    Latest successful backup
                  </h3>
                  <BackupLogTable
                    backupLogRow={
                      app.objectStorageBackup?.lastCompletedBackupLog
                    }
                  />
                </>
              )}
            </div>
          </>
        )}

        {!app.subscription.objectStorageBackup && (
          <div className="relative">
            <img
              src={imgScheduledBackup}
              alt="Scheduled backup form"
              className="max-w-md"
            />
            <div className="backdrop-blur-sm bg-white/30 absolute inset-0" />
          </div>
        )}
      </form>
    </Card>
  )
}

export default ScheduledBackup
