import React, { useState } from 'react'

import usePatchApp from '../../../../hooks/api/mutations/usePatchApp'
import type { App } from '../../../../hooks/api/queries/useApp'

import Card from '../../../../components/Card'
import FormField from '../../../../components/FormField'

import ReactTooltip from 'react-tooltip'
import { useForm } from 'react-hook-form'

import workingCopyImg from '../../../../images/working_copy.svg'
import approvalWorkFlowImg from '../../../../images/approval_workflow.svg'
import Button from '../../../../components/Button'

interface ApprovalWorkflowProps {
  app: App
}

const ApprovalWorkflow: React.FC<ApprovalWorkflowProps> = ({ app }) => {
  const approvalWorkflow = usePatchApp(app.appId, app.appEnv)

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<any>()

  const [useWorkingCopy, setWorkingCopy] = useState<boolean>(
    watch('useWorkingCopy') || app.useWorkingCopy
  )
  const [useApprovalWorkflow, setApprovalWorkflow] = useState<boolean>(
    watch('useApprovalWorkflow') || app.useApprovalWorkflow
  )

  const [isUpdated, setIsUpdated] = useState<boolean>(false)

  const workflowTooltip = app.subscription.approvalWorkflow
    ? 'Working copy must be enabled'
    : 'Not available for your plan'

  const hanldeChange = () => {
    const sub = watch(['useWorkingCopy', 'useApprovalWorkflow'])

    setValue('useWorkingCopy', sub[0])
    setWorkingCopy(sub[0])

    if (!sub[0]) {
      setValue('useApprovalWorkflow', false)
      setApprovalWorkflow(false)
    } else {
      setValue('useApprovalWorkflow', sub[1])
      setApprovalWorkflow(sub[1])
    }
  }

  return (
    <div>
      <Card
        title="Approval Workflow"
        helpText="Here you can configure the approval workflow as you need."
      >
        <div className="md:flex">
          <form
            className="w-56 shrink-0"
            onChange={hanldeChange}
            onSubmit={handleSubmit((data) => {
              approvalWorkflow.mutateAsync(data)
              setIsUpdated(true)
            })}
          >
            <div>
              <div
                data-for="workingCopy"
                data-tip={'Not available for your plan'}
              >
                <FormField
                  fieldName="useWorkingCopy"
                  type="checkbox"
                  register={register}
                  defaultValue={
                    useWorkingCopy !== undefined
                      ? useWorkingCopy
                      : app.useWorkingCopy
                  }
                  error={errors.appName}
                  disabled={!app.subscription.workingCopy}
                  label={'Enable working copy'}
                  checkboxLabelClass={
                    !app.subscription.workingCopy ? 'text-slate-400' : ''
                  }
                />
                {!app.subscription.workingCopy && (
                  <ReactTooltip
                    id="workingCopy"
                    place="bottom"
                    type="dark"
                    effect="float"
                  />
                )}
              </div>

              <div
                className="mb-6"
                data-for="approvalWorkflow"
                data-tip={workflowTooltip}
              >
                <FormField
                  fieldName="useApprovalWorkflow"
                  type="checkbox"
                  label="Approval workflow"
                  defaultValue={
                    useApprovalWorkflow !== undefined
                      ? useApprovalWorkflow
                      : app.useApprovalWorkflow
                  }
                  register={register}
                  error={errors.appName}
                  disabled={
                    !app.subscription.approvalWorkflow || !useWorkingCopy
                  }
                  checkboxLabelClass={
                    !app.subscription.approvalWorkflow ? 'text-slate-400' : ''
                  }
                />
                {(!app.subscription.approvalWorkflow || !useWorkingCopy) && (
                  <ReactTooltip
                    id="approvalWorkflow"
                    place="bottom"
                    type="dark"
                    effect="float"
                  />
                )}
              </div>

              <Button
                type="submit"
                color="cyan"
                loading={approvalWorkflow?.isLoading}
                disabled={
                  !(
                    app.subscription.approvalWorkflow ||
                    app.subscription.workingCopy
                  )
                }
                className="flex-1"
              >
                Update
              </Button>

              {isUpdated && (
                <div className="mt-4 text-sm text-gray-600 pr-4">
                  Remember to refresh the editor to see these changes applied
                </div>
              )}
            </div>
          </form>
          <div className="flex-1 flex items-center mt-8 md:mt-0 md:pl-6 md:border-l">
            {useWorkingCopy && !useApprovalWorkflow && (
              <img src={workingCopyImg} alt="React Bricks working copy" />
            )}
            {useApprovalWorkflow && (
              <img
                src={approvalWorkFlowImg}
                alt="React Bricks approval workflow"
              />
            )}
            {!useWorkingCopy && (
              <div className="text-gray-600">
                You can have just a "Working Copy" feature to decouple the saved
                page and the deployed one, or a full "Approval workflow" where
                content should always be approved.
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ApprovalWorkflow
