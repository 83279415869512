import React, { useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import * as gtag from '../../utils/gtag'

import useSignup from '../../hooks/api/mutations/useSignup'
import imgLogo from '../../images/reactbricks-logo-v.svg'

import FormField from '../../components/FormField'
import Button from '../../components/Button'
import useCheckInvitation from '../../hooks/api/queries/useCheckInvitation'

const SignUp: React.FC = () => {
  // FROM INVITE?
  const { invitationToken } = useParams()

  // NAVIGATE
  const navigate = useNavigate()

  // API SIGNUP
  const signup = useSignup()

  const { data } = useCheckInvitation(invitationToken)

  // FORM
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (data && data.message === 'OK') {
      reset({ email: data.email })
    }
  }, [data, reset])

  // EVENT HANDLERS
  const handleSignUp = (values: any) => {
    signup.mutateAsync({ ...values, invitationToken }).then((res) => {
      if (res && res.authToken && res.user) {
        // Set LocalStorage
        localStorage.setItem('token', res.authToken)
        localStorage.setItem('email', res.user.email)
        localStorage.setItem('firstName', res.user.firstName)
        localStorage.setItem('lastName', res.user.lastName)

        // Analytics Event
        gtag.trackEvent({
          category: 'sign_up',
          action: 'sign_up',
          label: 'Sign up completed',
        })

        // Redirect
        //navigate('/verify-email')
        navigate('/apps')
      }
    })
  }

  return (
    <div>
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1464618663641-bbdd760ae84a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80), linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.2)); background-blend-mode: overlay; background-size: cover; background-position: 50% 20% }`}</style>
      </Helmet>
      <header className="py-8 flex justify-center">
        <a
          href="https://reactbricks.com"
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <img src={imgLogo} alt="logo" className="w-32" />
        </a>
      </header>

      <div className="shadow-lg py-8 px-12 max-w-lg rounded-md mx-auto bg-white">
        <div className="flex flex-col">
          <h1 className="text-center text-gray-900 text-xl font-bold mb-8">Sign up for free</h1>

          <form onSubmit={handleSubmit(handleSignUp)}>
            <div className="mb-4 flex">
              <div className="flex-1 mr-4">
                <FormField
                  fieldName="firstName"
                  label="First name"
                  labelClassName="block text-sm leading-5 font-medium text-gray-700"
                  register={register}
                  validation={{ required: 'Enter your first name' }}
                  error={errors.firstName}
                />
              </div>
              <div className="flex-1">
                <FormField
                  fieldName="lastName"
                  label="Last name"
                  labelClassName="block text-sm leading-5 font-medium text-gray-700"
                  register={register}
                  validation={{ required: 'Enter your last name' }}
                  error={errors.lastName}
                />
              </div>
            </div>
            <div className="mb-4">
              <FormField
                fieldName="company"
                label="Company"
                labelClassName="block text-sm leading-5 font-medium text-gray-700"
                register={register}
              />
            </div>
            <div className="mb-4">
              <FormField
                fieldName="email"
                type="email"
                label="Email"
                labelClassName="block text-sm leading-5 font-medium text-gray-700"
                register={register}
                readOnly={data && data.email}
                validation={{
                  required: 'Enter your email address',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Invalid email address',
                  },
                }}
                error={errors.email}
              />
            </div>

            <div className="mb-4">
              <FormField
                fieldName="password"
                type="password"
                label="Password"
                labelClassName="block text-sm leading-5 font-medium text-gray-700"
                register={register}
                validation={{
                  required: 'Choose a password',
                  pattern: {
                    value:
                      /^.*(?=.{8,})((?=.*[?!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    message:
                      'At least 8 characters, lowercase and uppercase letters, a number and a special character',
                  },
                }}
                error={errors.password}
              />
            </div>
            <div className="mb-4">
              <label className="flex items-center text-gray-600">
                <input
                  className="form-checkbox"
                  type="checkbox"
                  {...register('acceptTerms', { required: true })}
                />
                <span className="ml-2 text-xs">
                  I agree to the{' '}
                  <a
                    href="https://reactbricks.com/legal/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline hover:text-blue-700"
                  >
                    Terms of service
                  </a>{' '}
                  and I acknowledge the{' '}
                  <a
                    href="https://reactbricks.com/legal/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline hover:text-blue-700"
                  >
                    Privacy policy
                  </a>
                </span>
              </label>
              {errors.acceptTerms && (
                <span className="text-sm text-red-500">Please, accept our terms</span>
              )}
            </div>

            <Button type="submit" full color="cyan">
              Sign up
            </Button>
          </form>
          <p className="text-xs text-gray-700 text-center mt-8">
            Do you have an account?{' '}
            <Link to="/" className="text-blue-600 hover:underline hover:text-blue-700">
              Log in here
            </Link>
          </p>
        </div>
      </div>

      <div className="mt-4 mb-16 text-gray-700 text-center text-sm">
        © 2021{' '}
        <a
          href="https://reactbricks.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-700"
        >
          ReactBricks.com
        </a>
      </div>
    </div>
  )
}

export default SignUp
