import React, { ReactElement } from 'react'
import { Routes, Route, NavLink, useMatch } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

interface MenuRoute {
  path: string
  label: string
  element: ReactElement
  separator?: boolean
  disabled?: boolean
}

interface AsideMenuProps {
  menuTitle: string
  routes: MenuRoute[]
  indexRoute: string
}

const AsideMenu: React.FC<AsideMenuProps> = ({ menuTitle, routes, indexRoute }) => {
  const isIndexRoot = useMatch(indexRoute)
  const firstRoute = routes[0]
  const otherRoutes = routes.slice(1)

  return (
    <div className="max-w-6xl mx-auto px-4 md:px-6 lg:px-8 py-12">
      <div className="flex items-start">
        <aside
          className={classNames(
            'w-full md:w-52 flex flex-col space-y-0 md:space-y-4 text-gray-600 flex-shrink-0',
            { 'hidden md:block': !isIndexRoot }
          )}
        >
          <h2 className="block md:hidden text-pink-600 text-2xl font-bold mb-4 md:mb-0">
            {menuTitle}
          </h2>
          <div className="py-4 md:py-0 border-b md:border-none hidden md:block">
            <NavLink
              to={indexRoute}
              end
              className={({ isActive }) =>
                isActive
                  ? 'block hover:text-pink-600 font-bold text-pink-600'
                  : 'block hover:text-pink-600'
              }
            >
              {firstRoute.label}
            </NavLink>
          </div>
          <div className="py-4 md:py-0 border-b md:border-none block md:hidden">
            <NavLink
              to={firstRoute.path}
              end
              className={({ isActive }) =>
                isActive
                  ? 'block hover:text-pink-600 font-bold text-pink-600'
                  : 'block hover:text-pink-600'
              }
            >
              {firstRoute.label}
            </NavLink>
          </div>
          {otherRoutes.map((route) => {
            return (
              <React.Fragment key={route.path}>
                <div className="py-4 md:py-0 border-b md:border-none">
                  {route.disabled ? (
                    <div
                      className="text-gray-400"
                      data-tip="Not available for your plan"
                      data-for="disabledRoute"
                    >
                      <ReactTooltip id="disabledRoute" place="left" type="dark" effect="solid" />
                      {route.label}
                    </div>
                  ) : (
                    <NavLink
                      to={route.path}
                      end
                      className={({ isActive }) =>
                        isActive
                          ? 'block hover:text-pink-600 font-bold text-pink-600'
                          : 'block hover:text-pink-600'
                      }
                    >
                      {route.label}
                    </NavLink>
                  )}
                </div>
                {route.separator && (
                  <div>
                    <hr className="w-3/4" />
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </aside>

        <div
          className={classNames('flex flex-1', {
            'hidden md:flex': isIndexRoot,
          })}
        >
          <div className="flex flex-col w-full">
            <div className="md:hidden mb-8">
              <NavLink to={indexRoute} className="inline-block py-2">
                <FaChevronLeft className="inline-block mr-3 mb-1" />
                <span className="font-bold tracking-wide">{menuTitle}</span>
              </NavLink>
            </div>

            <Routes>
              <Route path="/" element={firstRoute.element} />
              {routes.map((route) => {
                return <Route key={route.path} path={route.path} element={route.element} />
              })}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AsideMenu
