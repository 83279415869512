import React, { ReactElement } from 'react'

interface CardAvatarProps {
  title: string
  helpText?: ReactElement
}

const CardAvatar: React.FC<CardAvatarProps> = ({ title, helpText, children }) => {
  return (
    <div className="border p-6 rounded-md mb-8 flex items-center space-x-8 md:max-w-xl">
      <div>{children}</div>
      <div>
        <h2 className="text-xl text-pink-600 font-semibold mb-4">{title}</h2>
        {helpText && <p className="mb-6 text-sm max-w-lg text-gray-600">{helpText}</p>}
      </div>
    </div>
  )
}

export default CardAvatar
