import React, { useState } from 'react'
import { FiUser } from 'react-icons/fi'
import { useQueryClient } from 'react-query'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import useProfile from '../hooks/api/queries/useProfile'
import blankAvatar from '../images/avatar.svg'
import logo from '../images/reactbricks-logo.svg'
import './Header.css'
import HeaderMenu from './HeaderMenu'

const Header: React.FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data: user } = useProfile()

  const [userMenuOpen, setUserMenuOpen] = useState(false)

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('email')
    localStorage.removeItem('username')
    localStorage.removeItem('firstName')
    localStorage.removeItem('lastName')
    queryClient.clear()
    navigate('/')
  }

  const handlePause = () => {
    localStorage.removeItem('token')
    queryClient.clear()
    navigate('/pause')
  }

  const foto = user?.avatarUrl || blankAvatar

  return (
    <header className="bg-white border-b border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="bg-white">
          <div className="flex items-center justify-between h-16">
            <div className="left flex items-center">
              <img src={logo} alt="React Bricks" className="w-32 mr-4" />
            </div>
            <div
              className="right flex items-center relative cursor-pointer"
              onClick={() => {
                setUserMenuOpen((userMenuOpen) => !userMenuOpen)
              }}
            >
              <div className="w-8 h-8 border rounded-full flex justify-center items-center text-accent-600 bg-gray-50 border-gray-300">
                {foto ? (
                  <img
                    src={foto}
                    alt={user?.firstName + ' ' + user?.lastName}
                    className="rounded-full"
                  />
                ) : (
                  <FiUser />
                )}
              </div>
              <div className="text-sm text-gray-600 ml-2 leading-none">
                {user?.firstName} {user?.lastName}
              </div>
              {userMenuOpen && (
                <HeaderMenu
                  onClose={() => setUserMenuOpen(false)}
                  onPause={handlePause}
                  onLogout={handleLogout}
                />
              )}
            </div>
          </div>
        </nav>
        <nav className="bg-white">
          <div className="flex items-center justify-start text-md">
            <NavLink
              to="apps"
              className={({ isActive }) =>
                isActive
                  ? 'pt-1 pb-3 mr-8 border-b-4 border-pink-500 text-gray-900'
                  : 'pt-1 pb-3 mr-8 text-gray-600 border-b-4 border-transparent'
              }
            >
              Apps
            </NavLink>
            <NavLink
              to="user"
              className={({ isActive }) =>
                isActive
                  ? 'pt-1 pb-3 mr-8 border-b-4 border-pink-500 text-gray-900'
                  : 'pt-1 pb-3 mr-8 text-gray-600 border-b-4 border-transparent'
              }
            >
              User settings
            </NavLink>
          </div>
        </nav>
      </div>
      {user && !user.isVerified && (
        <div className="bg-pink-700 text-white py-1">
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            You haven't verified your e-mail address yet.{' '}
            <Link to="/verify-email" className="font-semibold underline">
              Verify it now
            </Link>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
