import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'

import useLogin from '../../hooks/api/mutations/useLogin'
// import Button from '../../components/Button'
import imgLogo from '../../images/reactbricks-logo-v.svg'

const Pause: React.FC = () => {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [time, setTime] = useState(format(new Date(), 'HH:mm'))
  // const [loading, setLoading] = useState(false)

  const login = useLogin()

  useEffect(() => {
    if (!localStorage.getItem('email')) {
      //navigate('/')
    }
    const timer = setInterval(() => {
      setTime(format(new Date(), 'HH:mm'))
    }, 10 * 1000)
    return () => clearInterval(timer)
  }, [])

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault()
    const email = localStorage.getItem('email')
    if (email && password) {
      login
        .mutateAsync({ email, password, keepLoggedIn: false })
        .then((res) => {
          if (res && res.authToken) {
            localStorage.setItem('token', res.authToken)
            localStorage.setItem('email', res.user.email)
            localStorage.setItem('firstName', res.user.firstName)
            localStorage.setItem('lastName', res.user.lastName)
            navigate('/apps')
          }
        })
    }
  }

  return (
    <div>
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1488998427799-e3362cec87c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80); background-size: cover; background-position: 50% 0%; background-blend-mode: multiply }`}</style>
      </Helmet>
      <header className="pt-16 pb-12 flex flex-col justify-center items-center">
        {/* <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/EBay_logo.svg/1280px-EBay_logo.svg.png"
          alt="logo"
          className="w-32 block mb-6"
        /> */}
        <div
          className="font-hairline leading-none"
          style={{
            fontSize: '12rem',
            color: 'hsl(71, 25%, 27%)',
            // textShadow: '0 0 15px hsla(207,70%,30%,0.7)',
          }}
        >
          {time}
        </div>
      </header>

      <div
        className="flex flex-col items-center py-12"
        style={{ backgroundColor: 'rgba(255,255,255,0.8)' }}
      >
        <div className="text-4xl font-thin mb-5 text-gray-800 leading-none">
          {localStorage.getItem('firstName')} {localStorage.getItem('lastName')}
        </div>
        <form
          onSubmit={handleLogin}
          className="flex justify-center items-center"
        >
          <input
            type="password"
            className="form-input bg-gray-50 focus:bg-white
            block rounded-md rounded-r-none border-gray-300 border-r-0 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />

          <button
            type="submit"
            className="py-2 px-6 rounded border border-cyan-500 bg-cyan-500 rounded-l-none text-white font-semibold hover:border-cyan-600 hover:bg-cyan-600 transition-colors duration-150"
          >
            Back to dashboard
          </button>
        </form>
      </div>

      <div className="flex justify-center pt-12">
        <img src={imgLogo} alt="logo" className="w-32" />
      </div>
    </div>
  )
}

export default Pause
