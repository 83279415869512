import React from 'react'

import Card from '../../../../components/Card'
import type { App } from '../../../../hooks/api/queries/useApp'
import SimmpleCodeTabs from '../../../../components/SimpleCodeTabs'

interface GettingStartedProps {
  app: App
}

const GettingStarted: React.FC<GettingStartedProps> = ({ app }) => {
  return (
    <Card title="Getting started">
      <div className="">
        <p className="mb-6">
          The best way to create a project for this App is using the{' '}
          <b>React Bricks CLI</b>:
        </p>
        {/* <pre className="p-6 rounded-lg bg-gray-900 text-white text-sm mb-6">
          <code>
            <span className="text-gray-400">$</span> npx
            create-reactbricks-app@latest
          </code>
        </pre> */}
        <SimmpleCodeTabs
          tabs={[
            {
              tabKey: 'npm',
              tabLabel: 'npm',
              code: 'npm create reactbricks-app@latest',
            },
            {
              tabKey: 'yarn',
              tabLabel: 'yarn',
              code: 'yarn create reactbricks-app',
            },
            {
              tabKey: 'pnpm',
              tabLabel: 'pnpm',
              code: 'pnpm create reactbricks-app@latest',
            },
          ]}
        />
        <p className="mt-6 mb-6">
          After authenticating, you'll be able to choose this App (
          <i>"{app.appName}"</i>).
        </p>
        <h2 className="mt-12 text-xl font-semibold mb-4 text-pink-600">
          Learn more
        </h2>

        <ul className="mb-6 list-disc pl-4">
          <li className="mb-1">
            Follow the{' '}
            <a
              href="https://reactbricks.com/learn"
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent-600 hover:text-accent-700 hover:underline font-bold"
            >
              Step by step Tutorial
            </a>
          </li>
          <li className="mb-1">
            Read the{' '}
            <a
              href="https://docs.reactbricks.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent-600 hover:text-accent-700 hover:underline font-bold"
            >
              Documentation
            </a>
          </li>
          <li className="mb-1">
            Watch the{' '}
            <a
              href="https://www.youtube.com/watch?v=LJ-BWiTcBoU"
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent-600 hover:text-accent-700 hover:underline font-bold"
            >
              1 hour Video Workshop
            </a>{' '}
            by our CTO
          </li>
        </ul>
      </div>
    </Card>
  )
}

export default GettingStarted
