import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import ReactTooltip from 'react-tooltip'
import { FiPlusCircle } from 'react-icons/fi'

import useAppUsers from '../../../../hooks/api/queries/useAppUsers'
import useAppInvitations from '../../../../hooks/api/queries/useAppInvitations'
import useProfile, { User } from '../../../../hooks/api/queries/useProfile'
import type { App } from '../../../../hooks/api/queries/useApp'
import useCustomRoles from '../../../../hooks/api/queries/useCustomRoles'

import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
import UsersRow from '../../../../components/Users/UsersRow'
import EditUser from '../../../../components/Users/EditUser'
import RemoveUser from '../../../../components/Users/RemoveUser'
import InviteUser from '../../../../components/Users/InviteUser'

import { modalCustomStyles } from '../../../../styles/modalStyles'
import classNames from 'classnames'
import TransferOwnership from '../../../../components/Users/TransferOwnership'

interface UsersProps {
  app: App
}

const Users: React.FC<UsersProps> = ({ app }) => {
  const [editUser, setEditUser] = useState<User | null>(null)
  const [deleteUser, setDeleteUser] = useState<User | null>(null)
  const [inviteUser, setInviteUser] = useState<boolean>(false)
  const [transferOwnership, setTransferOwnership] = useState<boolean>(false)

  const appUsersQuery = useAppUsers(app.appId, app.appEnv)
  const appInvitationsQuery = useAppInvitations(app.appId, app.appEnv)
  const { data: user } = useProfile()

  const customRolesQuery = useCustomRoles(
    app.appId,
    app.appEnv,
    app.subscription.flexibleRoles
  )

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [
    appUsersQuery.isLoading,
    appInvitationsQuery.isLoading,
    app.subscription.numUsers,
    app.subscription.maxUsers,
    appUsersQuery.data,
  ])

  const openEditModal = (user: User) => () => {
    setEditUser(user)
  }
  const openDeleteModal = (user: User) => () => {
    setDeleteUser(user)
  }
  const openInviteModal = () => {
    setInviteUser(true)
  }
  const openTransferOwnershipModal = () => {
    setTransferOwnership(true)
  }
  const closeEditModal = () => {
    setEditUser(null)
  }
  const closeDeleteModal = () => {
    setDeleteUser(null)
  }
  const closeInviteModal = () => {
    setInviteUser(false)
  }
  const closeTransferOwnershipModal = () => {
    setTransferOwnership(false)
  }

  const addUserDisabled =
    !user?.isVerified || app.subscription.numUsers >= app.subscription.maxUsers

  let tooltip = ''

  if (addUserDisabled) {
    if (!user?.isVerified) {
      tooltip =
        'You need to verify your e-mail address in order to invite users'
    } else {
      tooltip = `You reached the max number of users for the ${app.subscription.description}.`
    }
  }

  const adminsNotOwners =
    appUsersQuery?.data?.filter(
      (user) => !user.isOwner && user.role === 'ADMIN'
    ) || []

  const canTransferOwnership = !!adminsNotOwners.length && app.isOwner

  const cannotTransferTooltip = !app.isOwner
    ? "Only the App's owner can transfer ownership"
    : adminsNotOwners.length === 0
    ? 'You need at least another Admin user to transfer the ownership'
    : ''

  return (
    <div>
      {appUsersQuery?.data && (
        <Card
          title="Users"
          actionButton={
            <Button
              color="green"
              className="flex items-center pl-3 mb-4 relative"
              onClick={openInviteModal}
              disabled={addUserDisabled}
            >
              {addUserDisabled && (
                <>
                  <div
                    className="absolute top-0 bottom-0 right-0 left-0"
                    data-for="userCan"
                    data-tip={tooltip}
                  />
                  <ReactTooltip
                    id="userCan"
                    place="top"
                    type="dark"
                    effect="float"
                  />
                </>
              )}
              <FiPlusCircle
                className={`mr-2 ${addUserDisabled ? '' : 'text-lime-200'}`}
              />
              Invite new user
            </Button>
          }
        >
          {appUsersQuery.data.map((user) => {
            return (
              <UsersRow
                key={user.id}
                user={user}
                onOpenEditModal={openEditModal}
                onOpenDeleteModal={openDeleteModal}
                hasFlexibleRoles={app.subscription.flexibleRoles}
              />
            )
          })}
          {appInvitationsQuery &&
            appInvitationsQuery.data &&
            appInvitationsQuery.data.map((invitation) => {
              return (
                <UsersRow
                  key={invitation.id}
                  user={invitation}
                  onOpenEditModal={openEditModal}
                  onOpenDeleteModal={openDeleteModal}
                  hasFlexibleRoles={app.subscription.flexibleRoles}
                />
              )
            })}

          <hr />
          <div className="flex justify-start">
            <button
              disabled={!canTransferOwnership}
              className={classNames(
                'mt-6 transition-colors py-0.5',
                canTransferOwnership
                  ? 'text-accent-600 hover:text-accent-700'
                  : 'text-gray-400'
              )}
              onClick={() => openTransferOwnershipModal()}
              data-for="transfer-ownership-button"
              data-tip={cannotTransferTooltip}
            >
              Transfer ownership
            </button>
            {!canTransferOwnership && (
              <ReactTooltip
                id="transfer-ownership-button"
                place="top"
                type="dark"
                className="max-w-xs"
              />
            )}
          </div>
        </Card>
      )}

      <Modal
        isOpen={!!editUser}
        onRequestClose={closeEditModal}
        style={modalCustomStyles}
      >
        {!!editUser && customRolesQuery && (
          <EditUser
            app={app}
            user={editUser}
            onCloseEditModal={closeEditModal}
            customRoles={customRolesQuery.data || []}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!deleteUser}
        onRequestClose={closeDeleteModal}
        style={modalCustomStyles}
      >
        {deleteUser && (
          <RemoveUser
            appId={app.appId}
            appEnv={app.appEnv}
            user={deleteUser}
            onCloseDeleteModal={closeDeleteModal}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!inviteUser}
        onRequestClose={closeInviteModal}
        style={modalCustomStyles}
      >
        {inviteUser && customRolesQuery && (
          <InviteUser
            app={app}
            onCloseInviteModal={closeInviteModal}
            customRoles={customRolesQuery.data || []}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!transferOwnership}
        onRequestClose={closeTransferOwnershipModal}
        style={modalCustomStyles}
      >
        <TransferOwnership
          app={app}
          onCloseModal={closeTransferOwnershipModal}
          adminsNotOwners={adminsNotOwners}
        />
      </Modal>
      <ReactTooltip
        id="helpDeployInvite"
        place="top"
        type="dark"
        effect="solid"
        className="w-52 z-[100000000000000]"
      />
      <ReactTooltip
        id="helpDeployEdit"
        place="top"
        type="dark"
        effect="solid"
        className="w-52 z-[100000000000000]"
      />
    </div>
  )
}

export default Users
