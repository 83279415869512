import React from 'react'
import Card from '../../../../components/Card'
import usePatchApp from '../../../../hooks/api/mutations/usePatchApp'
import type { App } from '../../../../hooks/api/queries/useApp'
import FormField from '../../../../components/FormField'
import { useForm } from 'react-hook-form'
import Button from '../../../../components/Button'

interface AppNameProps {
  app: App
}

const AppSettings: React.FC<AppNameProps> = ({ app }) => {
  const mutation = usePatchApp(app?.appId, app?.appEnv)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      appName: app.appName,
      hostname: app.hostname,
      adminUrl: app.adminUrl,
    },
  })

  return (
    <div>
      <Card title="App Settings">
        <form onSubmit={handleSubmit((data) => mutation.mutateAsync(data))}>
          <div className="mb-4">
            <FormField
              fieldName="appName"
              type="text"
              label="App name"
              register={register}
              validation={{ required: 'App name is required' }}
              error={errors.appName}
            />
          </div>
          <div className="mb-4 relative">
            <FormField
              fieldName="hostname"
              type="text"
              label="Frontend url"
              register={register}
              validation={{
                pattern: {
                  value: /^(?!http).*$/,
                  message:
                    'Please, enter just the hostname (domain, without "https://")',
                },
              }}
              error={errors.hostname}
            />
            <a
              className="text-cyan-500 hover:text-cyan-600 underline transition-colors self-start absolute top-0 right-0"
              href={`https://${app.hostname}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View site
            </a>
          </div>
          <div className="mb-4 relative">
            <FormField
              fieldName="adminUrl"
              type="text"
              label="Admin url"
              register={register}
              error={errors.adminUrl}
            />
            <a
              className="text-cyan-500 hover:text-cyan-600 underline transition-colors self-start absolute top-0 right-0"
              href={`https://${app.adminUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View site
            </a>
          </div>
          <Button
            type="submit"
            color="cyan"
            loading={mutation.isLoading}
            className="flex-1"
          >
            Update
          </Button>
        </form>
      </Card>
    </div>
  )
}

export default AppSettings
