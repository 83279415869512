import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const useUpgradeDowngradeApp = (appId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (planId: number) =>
      await axios.post(`/account/apps/${appId}/change_subscription`, { planId }).then((res) => {
        return res.data
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['app', appId], data)
        setTimeout(() => {
          queryClient.invalidateQueries(['app', appId])
          queryClient.invalidateQueries('apps')
          cogoToast.success(`Plan changed successfully`, {
            position: 'bottom-right',
          })
        }, 2000)
      },
      onError: (error: any) => {
        console.log('ERROR: ', error?.message)
        cogoToast.error(`Error changing plan`, {
          position: 'top-center',
        })
      },
    }
  )
}

export default useUpgradeDowngradeApp
