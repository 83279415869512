import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { format, parseISO } from 'date-fns'
import Modal from 'react-modal'
import classNames from 'classnames'
import * as gtag from '../../../../utils/gtag'
import { FaCheckCircle } from 'react-icons/fa'

import useProfile from '../../../../hooks/api/queries/useProfile'
import useUpgradeDowngradeApp from '../../../../hooks/api/mutations/useUpgradeDowngradeApp'
import useEjectLiveDemo from '../../../../hooks/api/mutations/useEjectLiveDemo'

import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
import { Plan } from '../../../../components/PlanPill'

import { modalCustomStyles } from '../../../../styles/modalStyles'

import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import type { App } from '../../../../hooks/api/queries/useApp'
import QueryString from 'qs'

interface PlanData {
  planId: number
  paddleCode: number
}
const Plans: { [key: string]: PlanData } = {
  // Essential: { planId: 2, paddleCode: 597196 },
  Starter: { planId: 2, paddleCode: 597196 },
  Pro: { planId: 3, paddleCode: 644088 },
  Business: { planId: 6, paddleCode: 757776 },
  EnterpriseTrial: { planId: 10, paddleCode: 809468 },
  ScaleUp: { planId: 13, paddleCode: 881611 },
}

declare global {
  interface Window {
    Paddle: any
  }
}

type OperationType = 'Upgrade' | 'Downgrade' | 'Cancel'

type ButtonType =
  | 'Upgrade'
  | 'Downgrade'
  | 'ContactSales'
  | 'Active'
  | 'Enterprise Trial'

interface Feature {
  label: string
  value: string
}

interface PlansProps {
  app: App
}

const isAppSumo = (plan: Plan) => {
  return plan.startsWith('AppSumo')
}

const getButtonType = (currentPlan: Plan, plan: Plan): ButtonType => {
  // ACTIVE PLAN
  if (plan === currentPlan) {
    return 'Active'
  } else if (
    (plan === 'Pro' || plan === 'Basic') &&
    (currentPlan === 'Pro' || currentPlan === 'Basic')
  ) {
    // Basic is the same as Pro
    return 'Active'
  }

  // ENTERPRISE FROM/TO
  if (plan === 'Enterprise' || currentPlan === 'Enterprise') {
    return 'ContactSales'
  }

  // ENTERPRISE TRIAL
  if (plan === 'Enterprise Trial') {
    return 'Enterprise Trial'
  }

  // ESSENTIAL COLUMN
  if (plan === 'Starter') {
    if (currentPlan === 'Developer') {
      return 'Upgrade'
    }
    return 'Downgrade'
  }

  // PRO COLUMN
  if (plan === 'Pro' || plan === 'Basic') {
    if (
      currentPlan === 'Developer' ||
      currentPlan === 'Starter' ||
      isAppSumo(currentPlan)
    ) {
      return 'Upgrade'
    }
    return 'Downgrade'
  }

  // BUSINESS COLUMN
  if (plan === 'Business') {
    return 'Upgrade'
  }

  // SCALE UP COLUMN
  if (plan === 'Scale up') {
    return 'Upgrade'
  }

  return 'ContactSales'
}

interface ChangeButtonProps {
  buttonType: ButtonType
  appId: string
  appEnv: string
  currentPlan: Plan
  planId?: number
  productCode?: number
  isVerified?: boolean
  openPurchaseModal: (operationType: OperationType) => void
}

const ChangePlanButton: React.FC<ChangeButtonProps> = ({
  buttonType,
  appId,
  appEnv,
  currentPlan,
  planId,
  productCode,
  isVerified = false,
  openPurchaseModal,
}) => {
  const upgradeDowngradeApp = useUpgradeDowngradeApp(appId)
  const { data: user } = useProfile()
  const queryClient = useQueryClient()

  // HANDLE UPGRADE & DOWNGRADE
  const handleUpgradeDowngradeApp = (
    planId: number,
    upgradeDowngrade: OperationType
  ) => {
    upgradeDowngradeApp.mutate(planId)
    openPurchaseModal(upgradeDowngrade)
    queryClient.invalidateQueries(['app', appId, appEnv])
    queryClient.invalidateQueries('apps')
  }

  // HANDLE BUY
  const handleBuyPlan = (productCode: number) => {
    window.Paddle.Checkout.open({
      product: productCode,
      email: user?.email,
      allowQuantity: false,
      quantity: 1,
      passthrough: JSON.stringify({ appId }),
      customData: { appId },
      successCallback: () => {
        openPurchaseModal('Upgrade')
        setTimeout(() => {
          queryClient.invalidateQueries(['app', appId, appEnv])
          queryClient.invalidateQueries('apps')
        }, 6000)

        // Analytics Event
        gtag.trackEvent({
          category: 'purchase',
          action: 'purchase',
          label: productCode.toString(),
        })
      },
    })
  }

  if (buttonType === 'ContactSales') {
    return (
      <a
        href="https://reactbricks.com/contact-sales"
        target="_blank"
        rel="noreferrer"
        className="block text-center text-lg py-2 px-3 sm:px-5 rounded-full text-white font-medium bg-indigo-800 hover:bg-indigo-900 hover:shadow-lg transition duration-200"
      >
        Contact Sales
      </a>
    )
  }
  if (buttonType === 'Active') {
    return (
      <div className="block w-full text-center text-base uppercase tracking-wide py-3 px-3 bg-green-100 rounded-full border border-green-200 text-green-600 font-medium">
        Active
      </div>
    )
  }
  if (buttonType === 'Upgrade') {
    return (
      <button
        type="button"
        className={classNames(
          'block w-full text-center text-lg py-2 px-3 sm:px-5 rounded-full text-white font-medium hover:shadow-lg transition duration-200',
          isVerified
            ? planId === 2
              ? 'bg-pink-500 hover:bg-pink-600'
              : 'bg-blue-500 hover:bg-blue-600'
            : 'bg-gray-500 cursor-not-allowed'
        )}
        disabled={!isVerified}
        onClick={() => {
          if (
            (currentPlan === 'Developer' || isAppSumo(currentPlan)) &&
            productCode
          ) {
            handleBuyPlan(productCode)
          } else if (planId) {
            handleUpgradeDowngradeApp(planId, 'Upgrade')
          }
        }}
      >
        Upgrade
      </button>
    )
  }
  if (buttonType === 'Downgrade') {
    return (
      <button
        type="button"
        className={classNames(
          'block w-full text-center text-lg py-2 px-3 sm:px-5 rounded-full hover:text-white font-medium border-2 hover:shadow-lg transition duration-200',
          isVerified
            ? 'text-gray-500 border-gray-500 hover:bg-gray-500'
            : 'text-gray-400 border-gray-400 hover:bg-gray-400 cursor-not-allowed'
        )}
        disabled={!isVerified}
        onClick={() => {
          planId && handleUpgradeDowngradeApp(planId, 'Downgrade')
        }}
      >
        Downgrade
      </button>
    )
  }
  if (buttonType === 'Enterprise Trial') {
    return (
      <button
        type="button"
        className={classNames(
          'block w-full text-center text-sm py-1 px-3 sm:px-5 rounded-full hover:text-white font-medium border-2 hover:shadow-lg transition duration-200',
          isVerified
            ? 'text-cyan-500 border-cyan-500 hover:bg-cyan-500'
            : 'text-gray-400 border-gray-400 hover:bg-gray-400 cursor-not-allowed'
        )}
        disabled={!isVerified}
        onClick={() => {
          if (
            (currentPlan === 'Developer' || isAppSumo(currentPlan)) &&
            productCode
          ) {
            handleBuyPlan(productCode)
          } else if (planId) {
            handleUpgradeDowngradeApp(planId, 'Upgrade')
          }
        }}
      >
        Test for 3 weeks for $50
      </button>
    )
  }
  return null
}

interface CancelSubscriptionLinkProps {
  cancelUrl: string
  isOutsideTable?: boolean
  appId: string
  openPurchaseModal: (operationType: OperationType) => void
}

const CancelSubscriptionLink: React.FC<CancelSubscriptionLinkProps> = ({
  cancelUrl,
  isOutsideTable = false,
  appId,
  openPurchaseModal,
}) => {
  const queryClient = useQueryClient()
  const handleCancelSubscription = () => {
    window.Paddle.Checkout.open({
      override: cancelUrl,
      successCallback: () => {
        openPurchaseModal('Cancel')
        setTimeout(() => {
          queryClient.invalidateQueries(['app', appId])
          queryClient.invalidateQueries('apps')
        }, 3000)
      },
    })
  }

  return (
    <button
      className={classNames(
        'text-accent-600 hover:text-accent-700 hover:underline font-semibold',
        {
          'block py-4 border-t mx-4 text-sm leading-none': !isOutsideTable,
        }
      )}
      onClick={handleCancelSubscription}
    >
      Cancel subscription
    </button>
  )
}

const PlanPage: React.FC<PlansProps> = ({ app }) => {
  const queryClient = useQueryClient()
  const { data: user } = useProfile()
  const { appId } = useParams()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const activePlan: Plan = app?.plan

  const fromPlan = (
    QueryString.parse(location.search, { ignoreQueryPrefix: true }).p || ''
  ).toString()

  if (
    fromPlan &&
    (fromPlan === 'st' || fromPlan === 'pr') &&
    activePlan === 'Developer'
  ) {
    const productCode =
      fromPlan === 'st' ? Plans.Starter.paddleCode : Plans.Pro.paddleCode

    window.Paddle.Checkout.open({
      product: productCode,
      email: user?.email,
      allowQuantity: false,
      quantity: 1,
      passthrough: JSON.stringify({ appId }),
      customData: { appId },
      successCallback: () => {
        searchParams.delete('p')
        setSearchParams(searchParams)

        openPurchaseModal('Upgrade')

        setTimeout(() => {
          queryClient.invalidateQueries(['app', appId, app?.appEnv])
          queryClient.invalidateQueries('apps')
        }, 6000)

        // Analytics Event
        gtag.trackEvent({
          category: 'purchase',
          action: 'purchase',
          label: productCode.toString(),
        })
      },
    })
  }

  const ejectLiveDemo = useEjectLiveDemo(app.appId)

  // Modal state
  const [purchaseModalOpen, setPurchaseModalOpen] = useState<boolean>(false)
  const [purchaseModalTitle, setPurchaseModalTitle] = useState<string>('')
  const [purchaseModalText, setPurchaseModalText] = useState<string>('')

  const openPurchaseModal = (operationType: OperationType) => {
    // UPGRADE
    if (operationType === 'Upgrade') {
      setPurchaseModalTitle('Your App was successfully upgraded!')
      setPurchaseModalText(
        "Thank you for trusting us. We really appreciate your choice and we'll do our best to meet your expectations."
      )
    }
    // DOWNGRADE
    if (operationType === 'Downgrade') {
      setPurchaseModalTitle('Your App was successfully downgraded')
      setPurchaseModalText(
        'Your next payment will have a discount based on the days left from the Entry plan.'
      )
    }
    // CANCEL
    if (operationType === 'Cancel') {
      setPurchaseModalTitle('Your subscription was cancelled')
      setPurchaseModalText(
        `Now you are on the free "Developer" plan. Thank you for trusting us. If anything went wrong with React Bricks, please let us know.`
      )
    }
    setPurchaseModalOpen(true)
  }

  const closeCreateAppModal = () => {
    setPurchaseModalOpen(false)
  }

  const handleEjectLiveDemo = () => {
    ejectLiveDemo.mutate()
  }

  return (
    <>
      {app.appEnv === 'main' ? (
        <Card title="Your Plan">
          <div className="mb-12">
            {!!app.liveDemo && app.liveDemo.status === 'ACTIVE' ? (
              <>
                <div className="p-6 rounded bg-pink-50 border border-pink-300 mb-6">
                  This is a <b>LIVE DEMO</b>.
                  {app.liveDemo.expiresAt && (
                    <>
                      <br />
                      It will be hosted on Netlify until{' '}
                      <b>
                        {format(
                          parseISO(app.liveDemo.expiresAt),
                          'MMMM dd, yyyy HH:mm'
                        )}
                      </b>
                      .
                    </>
                  )}
                  {app.liveDemo.deleteAppAt && (
                    <>
                      <br />
                      The app will be deleted from the Dashboard on{' '}
                      <b>
                        {format(
                          parseISO(app.liveDemo.deleteAppAt),
                          'MMMM dd, yyyy HH:mm'
                        )}
                      </b>
                      .
                    </>
                  )}
                </div>
                <div className="mb-12 pl-4 pb-1 border-l-4 border-amber-400">
                  <p className="mb-3">
                    You may also keep this app, so that you don't lose the
                    content created during the demo:
                  </p>
                  <Button
                    type="button"
                    color="cyan"
                    onClick={handleEjectLiveDemo}
                  >
                    Keep this app
                  </Button>
                </div>
              </>
            ) : (
              <p className="mb-2">
                You are on the
                <span className="font-bold">
                  {activePlan === 'Developer' &&
                  app.subscription.description === 'Boosted Developer Plan'
                    ? ' Boosted Developer '
                    : activePlan === 'Developer'
                    ? ' FREE '
                    : activePlan === 'Pro'
                    ? ' BASIC '
                    : activePlan === 'Pro - yearly'
                    ? ' BASIC - YEARLY '
                    : activePlan === 'Basic'
                    ? ' BASIC '
                    : activePlan === 'Scale up'
                    ? ' SCALE UP '
                    : activePlan === 'Starter'
                    ? ' STARTER '
                    : ` ${activePlan.toUpperCase()} `}
                </span>
                plan.
              </p>
            )}

            {!!isAppSumo(activePlan) ? (
              <p className="mb-6">
                Next renewal: <b>None (lifetime deal)</b>
              </p>
            ) : (
              <>
                <p className="mb-6">
                  {activePlan === 'Developer' ? (
                    <span>
                      Update to a paid plan to overcome the limits of the free
                      plan.
                    </span>
                  ) : activePlan === 'Enterprise Trial' ? (
                    <span>
                      Your trial expires on{' '}
                      {format(
                        parseISO(app.subscription.periodTo),
                        'MMMM dd, yyyy'
                      )}
                      .
                    </span>
                  ) : (
                    <span>
                      Next renewal on{' '}
                      {format(
                        parseISO(app.subscription.periodTo),
                        'MMMM dd, yyyy'
                      )}
                      .
                    </span>
                  )}
                </p>
                {activePlan !== 'Developer' &&
                  activePlan !== 'Enterprise' &&
                  activePlan !== 'Enterprise Trial' && (
                    <div>
                      <CancelSubscriptionLink
                        appId={app.appId}
                        cancelUrl={app.subscription.cancelUrl}
                        isOutsideTable
                        openPurchaseModal={openPurchaseModal}
                      />{' '}
                      <span className="text-sm">
                        (downgrade to free Developer plan)
                      </span>
                    </div>
                  )}
                {activePlan === 'Enterprise' && (
                  <div>
                    <a
                      href="mailto:support@reactbricks.com"
                      className="text-accent-600 hover:text-accent-700 hover:underline font-semibold"
                    >
                      Contact us
                    </a>{' '}
                    for changes to your plan
                  </div>
                )}
              </>
            )}
          </div>
          {activePlan !== 'Enterprise' && (
            <>
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-semibold text-pink-600">
                  Plan {!isAppSumo(activePlan) && <span>main </span>}features:
                </h2>
                {!isAppSumo(activePlan) && (
                  <div className="text-sm">
                    See{' '}
                    <a
                      href="https://reactbricks.com/pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-accent-600 hover:text-accent-700 hover:underline font-bold"
                    >
                      Pricing
                    </a>{' '}
                    for details
                  </div>
                )}
              </div>

              <table className="table-fixed w-full mb-12">
                <tbody>
                  {/* {app.subscription.features.map((feature: Feature) => {
                    return (
                      <tr key={feature.label}>
                        <td className="w-1/3 p-2 border-b text-sm font-semibold pr-6">
                          {feature.label}
                        </td>
                        <td className="w-2/3 p-2 border-b text-sm">
                          {feature.value}
                        </td>
                      </tr>
                    )
                  })} */}
                  <tr>
                    <td className="w-1/3 p-2 border-b text-sm font-semibold pr-6">
                      Users
                    </td>
                    <td className="w-2/3 p-2 border-b text-sm">
                      {app.subscription.maxUsers}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/3 p-2 border-b text-sm font-semibold pr-6">
                      Pages
                    </td>
                    <td className="w-2/3 p-2 border-b text-sm">
                      {app.subscription.maxPages}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/3 p-2 border-b text-sm font-semibold pr-6">
                      Locales
                    </td>
                    <td className="w-2/3 p-2 border-b text-sm">
                      {app.subscription.maxLocales}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/3 p-2 border-b text-sm font-semibold pr-6">
                      History retention
                    </td>
                    <td className="w-2/3 p-2 border-b text-sm">
                      {app.subscription.changeHistoryRetentionDays}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/3 p-2 border-b text-sm font-semibold pr-6">
                      Support
                    </td>
                    <td className="w-2/3 p-2 border-b text-sm">
                      {app.subscription.support}
                    </td>
                  </tr>
                </tbody>
              </table>

              {activePlan !== 'Enterprise Trial' && (
                <>
                  <h2 className="text-xl font-semibold text-pink-600 mb-6">
                    Change Plan
                  </h2>

                  {isAppSumo(activePlan) && (
                    <a
                      href={app.subscription.updateUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-block mb-6 focus:shadow-outline focus:outline-none text-white font-semibold leading-5 rounded transition duration-150 py-3 px-5 bg-appsumoyellow-200 hover:bg-appsumoyellow-100 hover:shadow-2xl"
                    >
                      Change your AppSumo subscription &raquo;
                    </a>
                  )}

                  {user && !user.isVerified && (
                    <p className="inline-block mb-6 text-pink-600 py-1 px-4 border-l-4 border-pink-600 bg-pink-50">
                      You need to verify your e-mail in order to upgrade your
                      plan.{' '}
                      <a
                        className="font-semibold underline"
                        href="/verify-email"
                      >
                        Verify it now
                      </a>
                    </p>
                  )}

                  {activePlan === 'Developer' || isAppSumo(activePlan) ? (
                    <p>
                      Upgrade to a paid subscription to have our direct support
                      and overcome your current limits on the App.
                    </p>
                  ) : (
                    <p>
                      Upgrade your plan to leverage new features and overcome
                      the current limits for your users.
                    </p>
                  )}

                  <div>
                    See{' '}
                    <a
                      href="https://reactbricks.com/pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-accent-600 hover:text-accent-700 hover:underline font-bold"
                    >
                      Pricing
                    </a>{' '}
                    for plans' details.
                  </div>

                  {/* PRICING TABLE 2024 */}
                  <div className="flex flex-wrap justify-center -mx-2">
                    <div className="mt-6 mb-4 mx-2 p-5 border border-t-4 border-t-pink-500 rounded flex-1 min-w-[250px] text-center flex flex-col">
                      <div className="h-10">
                        <h2 className="text-2xl text-pink-500 font-bold mb-4">
                          Starter
                        </h2>
                      </div>
                      <div className="h-24 mb-2 flex flex-col justify-center">
                        <strong className="block text-3xl font-bold">
                          $ 16
                        </strong>
                        <p className="text-gray-500">per app / month</p>
                      </div>
                      <ChangePlanButton
                        buttonType={getButtonType(activePlan, 'Starter')}
                        appId={app.appId}
                        appEnv={app.appEnv}
                        currentPlan={
                          isAppSumo(activePlan) ? 'Developer' : activePlan
                        }
                        productCode={Plans.Starter.paddleCode}
                        planId={Plans.Starter.planId}
                        openPurchaseModal={openPurchaseModal}
                        isVerified={user && user.isVerified}
                      />
                    </div>

                    <div className="mt-6 mb-4 mx-2 p-5 border border-t-4 border-t-blue-500 rounded flex-1 min-w-[250px] text-center flex flex-col">
                      <div className="h-10">
                        <h2 className="text-2xl text-blue-500 font-bold mb-4">
                          Pro
                        </h2>
                      </div>
                      <div className="h-24 mb-2 flex flex-col justify-center">
                        <strong className="block text-3xl font-bold">
                          $ 99
                        </strong>
                        <p className="text-gray-500">per app / month</p>
                      </div>
                      <ChangePlanButton
                        buttonType={getButtonType(activePlan, 'Pro')}
                        appId={app.appId}
                        appEnv={app.appEnv}
                        currentPlan={
                          isAppSumo(activePlan) ? 'Developer' : activePlan
                        }
                        productCode={Plans.Pro.paddleCode}
                        planId={Plans.Pro.planId}
                        openPurchaseModal={openPurchaseModal}
                        isVerified={user && user.isVerified}
                      />
                    </div>

                    {/* <div className="mt-6 mb-4 mx-2 p-5 border border-t-4 border-t-blue-700 rounded flex-1 min-w-[250px] text-center flex flex-col">
                      <div className="h-6 self-center" />
                      <div className="h-10">
                        <h2 className="text-2xl text-blue-700 font-bold mb-4">
                          Scale up
                        </h2>
                      </div>
                      <div className="h-24 mb-2 flex flex-col justify-center">
                        <strong className="block text-3xl font-bold">
                          $ 749
                        </strong>
                        <p className="text-gray-500">per app / month</p>
                      </div>
                      <ChangePlanButton
                        buttonType={getButtonType(activePlan, 'Scale up')}
                        appId={app.appId}
                        currentPlan={
                          isAppSumo(activePlan) ? 'Developer' : activePlan
                        }
                        productCode={Plans.ScaleUp.paddleCode}
                        planId={Plans.ScaleUp.planId}
                        openPurchaseModal={openPurchaseModal}
                        isVerified={user && user.isVerified}
                      />
                    </div> */}

                    <div className="mt-6 mb-4 mx-2 p-5 border border-t-4 border-t-indigo-800 rounded flex-1 min-w-[250px] text-center flex flex-col">
                      <div className="h-10">
                        <h2 className="text-2xl text-indigo-800 font-bold mb-4">
                          Enterprise
                        </h2>
                        {/* <p className="text-lg text-gray-600">
                          Enterprise-grade features and premium support.
                        </p> */}
                      </div>
                      <div className="h-24 mb-2 flex flex-col justify-center">
                        <strong className="block text-3xl font-bold">
                          Let's talk!
                        </strong>
                      </div>
                      <ChangePlanButton
                        buttonType={getButtonType(activePlan, 'Enterprise')}
                        appId={app.appId}
                        appEnv={app.appEnv}
                        currentPlan={
                          isAppSumo(activePlan) ? 'Developer' : activePlan
                        }
                        openPurchaseModal={openPurchaseModal}
                        isVerified={user && user.isVerified}
                      />
                      {/* {activePlan === 'Developer' && (
                        <div className="mt-2">
                          <ChangePlanButton
                            buttonType={getButtonType(
                              activePlan,
                              'Enterprise Trial'
                            )}
                            appId={app.appId}
                            currentPlan={
                              isAppSumo(activePlan) ? 'Developer' : activePlan
                            }
                            productCode={Plans.EnterpriseTrial.paddleCode}
                            planId={Plans.EnterpriseTrial.planId}
                            openPurchaseModal={openPurchaseModal}
                            isVerified={user && user.isVerified}
                          />
                        </div>
                      )} */}
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          <Modal
            isOpen={!!purchaseModalOpen}
            onRequestClose={closeCreateAppModal}
            style={modalCustomStyles}
          >
            <div className="px-12 py-8 max-w-lg">
              <div className="flex justify-center text-6xl text-green-500 mb-3">
                <FaCheckCircle />
              </div>

              <h2 className="text-xl font-semibold text-center mb-6">
                {purchaseModalTitle}
              </h2>

              <div className="mb-6 text-lg text-gray-600">
                {purchaseModalText}
              </div>

              <Button
                type="button"
                color="cyan"
                full
                onClick={closeCreateAppModal}
              >
                Back to your App
              </Button>
            </div>
          </Modal>
        </Card>
      ) : (
        <Card title="Your plan 🔐">
          <p>
            You cannot edit your plan from this page. Visit the{' '}
            <Link
              to={`/apps/${appId}/environments/main/plan`}
              className="text-accent-600 hover:text-accent-700 hover:underline font-bold"
            >
              main environment
            </Link>{' '}
            page to change it.
          </p>
        </Card>
      )}
    </>
  )
}

export default PlanPage
