import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import useCheckInvitation from '../../hooks/api/queries/useCheckInvitation'
import useAcceptInvitation from '../../hooks/api/mutations/useAcceptInvitation'

import Loading from '../../components/Loading'
import Button from '../../components/Button'

import imgLogo from '../../images/reactbricks-logo-v.svg'

const Login: React.FC = () => {
  const [tokenValid, setTokenValid] = useState(false)
  const [invitationAnswered, setInvitationAnswered] = useState('')
  const { invitationToken } = useParams()
  const { data, isLoading } = useCheckInvitation(invitationToken)
  const acceptInvitation = useAcceptInvitation(invitationToken)

  useEffect(() => {
    if (data && data.message === 'OK') {
      setTokenValid(true)
    }
  }, [data])

  const handleAccept = (accept: boolean) => async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      const res = await acceptInvitation.mutateAsync(accept)
      if (res?.message === 'OK') {
        setInvitationAnswered(accept ? 'ACCEPTED' : 'REJECTED')
      } else {
        setInvitationAnswered('')
      }
    } catch (error) {
      setInvitationAnswered('')
    }
  }

  const renderContent = (isLoading: boolean, invitationAnswered: string, tokenValid: boolean) => {
    // LOADING
    if (isLoading) {
      return (
        <div className="flex">
          <Loading />
        </div>
      )
    }

    // NO ANSWER YET
    if (!invitationAnswered) {
      // INVALID TOKEN
      if (!tokenValid) {
        return (
          <div className="flex flex-col items-center">
            <h2 className="text-center text-gray-900 text-lg font-bold mb-6">Invalid link</h2>
            <p className="mb-6 text-center">This link you clicked on is not valid or it is expired.</p>
            <Link to="/apps" className="block text-sm text-center text-blue-600 hover:text-blue-700">
              Back to home
            </Link>
          </div>
        )
      }
      // TOKEN OK E UTENTE REGISTRATO
      if (data.firstName !== null && data.lastName !== null) {
        return (
          <>
            <h2 className="text-center text-gray-900 text-xl font-bold mb-4">Join {data.app.name}</h2>
            <p className="mb-6 text-gray-600">
              <span className="font-semibold text-gray-700">
                {data.sender.firstName} {data.sender.lastName}
              </span>{' '}
              has invited you to collaborate on <span className="font-semibold text-gray-700">{data.app.name}</span> as{' '}
              {data.role.toLowerCase()}.
            </p>
            <div className="flex justify-between space-x-3">
              <Button full color="gray" onClick={handleAccept(false)} loading={acceptInvitation.isLoading}>
                Reject
              </Button>
              <Button full color="cyan" onClick={handleAccept(true)} loading={acceptInvitation.isLoading}>
                Accept
              </Button>
            </div>
          </>
        )
      }
      // TOKEN OK E NUOVO UTENTE
      return (
        <>
          <h2 className="text-center text-gray-900 text-xl font-bold mb-4">Join {data.app.name}</h2>
          <p className="mb-6 text-gray-600">
            <span className="font-semibold text-gray-700">
              {data.sender.firstName} {data.sender.lastName}
            </span>{' '}
            has invited you to collaborate on <span className="font-semibold text-gray-700">{data.app.name}</span> as{' '}
            {data.role.toLowerCase()}.
          </p>
          <div className="flex justify-between space-x-3">
            <Button full color="gray" onClick={handleAccept(false)} loading={acceptInvitation.isLoading}>
              Reject
            </Button>
            <Link
              className="block w-full text-center bg-accent-600 hover:bg-accent-700 focus:shadow-outline focus:outline-none text-white font-semibold leading-5 py-3 px-5 rounded transition-colors duration-150"
              to={`/sign-up/${invitationToken}`}
            >
              Sign up
            </Link>
          </div>
        </>
      )
    }

    // ANSWER YES
    if (invitationAnswered === 'ACCEPTED') {
      return (
        <>
          <h2 className="text-center text-gray-900 text-xl font-bold mb-4">Invitation accepted</h2>
          <p className="mb-6 text-gray-600">
            You accepted to collaborate on <span className="font-semibold text-gray-700">{data.app.name}</span> as{' '}
            {data.role.toLowerCase()}.
          </p>
          <Link to="/apps" className="block text-sm text-center text-blue-600 hover:text-blue-700">
            Go to the project
          </Link>
        </>
      )
    }

    // ANSWER NO
    if (invitationAnswered === 'REJECTED') {
      return (
        <>
          <h2 className="text-center text-gray-900 text-xl font-bold mb-4">Invitation rejected</h2>
          <p className="mb-6 text-gray-600">
            You rejected the invitation to collaborate on{' '}
            <span className="font-semibold text-gray-700">{data.app.name}</span> as {data.role.toLowerCase()}.
          </p>
          <Link to="/apps" className="block text-sm text-center text-blue-600 hover:text-blue-700">
            Back to home
          </Link>
        </>
      )
    }

    // NON DOVREBBE MAI SUCCEDERE
    return null
  }

  return (
    <div>
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1464618663641-bbdd760ae84a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80), linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.2)); background-blend-mode: overlay; background-size: cover; background-position: 50% 20% }`}</style>
      </Helmet>
      <header className="py-12 flex justify-center">
        <img src={imgLogo} alt="logo" className="w-32" />
      </header>

      <div className="shadow-lg py-8 px-10 max-w-sm rounded mx-auto bg-white text-center">
        {renderContent(isLoading, invitationAnswered, tokenValid)}
      </div>
    </div>
  )
}

export default Login
