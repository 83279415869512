import React from 'react'
import PlanPill, { Plan } from './PlanPill'
import { FaCrown } from 'react-icons/fa'

interface AccountProps {
  id: string
  name: string
  company: string
}

interface UserAppProps {
  app: {
    appId: string
    appName: string
    appEnv: string
    plan: Plan
    account: AccountProps
    role: string
    isOwner: string
    liveDemo?: { expiresAt: string; deleteAppAt: string; status: string }
    ////////////
    //This piece of data is not available from the APIs at this level
    // subscription: {
    //   description: string
    // }
    ////////////
  }
}

const UserApp: React.FC<UserAppProps> = ({
  app: {
    appName,
    appEnv,
    plan,
    account,
    role,
    isOwner,
    liveDemo,
    // subscription
  },
}) => {
  return (
    <div className="px-6 py-5 rounded-lg bg-white shadow-md hover:shadow-2xl relative transition-shadow duration-150">
      <div className="absolute top-0 right-0" style={{ right: -2 }}>
        <PlanPill
          plan={plan}
          isLabel
          isLiveDemo={!!liveDemo && liveDemo.status === 'ACTIVE'}
          // isBoosted={subscription?.description === 'Boosted Developer Plan'}
        />
      </div>
      <div className="uppercase tracking-wide text-pink-700 text-xs truncate">
        {account.name}
      </div>
      <div className="mb-5 truncate">
        <h2 className="text-2xl font-semibold">{appName}</h2>
        <p className="text-base font-light">{appEnv}</p>
      </div>
      <div className="flex flex-wrap space-x-3">
        {isOwner && (
          <div className="flex items-center py-1 px-2 leading-none bg-yellow-200 text-yellow-900 rounded text-xs font-semibold uppercase">
            <FaCrown className="text-yellow-600 mr-1" />
            Owner
          </div>
        )}
        <span className="py-1 px-2 leading-none bg-gray-200 text-gray-700 rounded text-xs font-semibold uppercase">
          {role}
        </span>
      </div>
    </div>
  )
}

export default UserApp
